<template>
    <h2 class="primary--text py-10">{{ title }}</h2>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true,
            default: 'Título'
        }
    }
}
</script>